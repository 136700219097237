<template>
  <div>
    <div class="message-box">
      <div class="message-box-box">
        <div class="message-box-left" v-if="instDate">
          <div class="title">说明书</div>
          <div class="pattern">
            <div class="chose" @click="changePattern(1)">表格模式</div>
            <div @click="changePattern(2)">富文本模式</div>
          </div>
          <div>
            <div v-for="(item, index) in instruction" :key="index" class="box">
              <div class="lable">{{ item.name }}:</div>
              <div class="input-box">
                <el-input
                  v-if="$route.query.type || instDate[item.key]"
                  type="textarea"
                  :disabled="$route.query.type == 3 ? false : true"
                  v-model="instDate[item.key]"
                  :placeholder="`请输入${item.name}`"
                ></el-input>
                <span v-else>/</span>
              </div>
            </div>
          </div>

          <!-- <el-descriptions :column="1">
            <el-descriptions-item
              :label="item.name"
              width="200px"
              v-for="(item, index) in instruction"
              :key="index"
            >
              <div class="input-box">
                <el-input
                  v-if="$route.query.type || instDate[item.key]"
                  type="textarea"
                  :disabled="$route.query.type == 3 ? false : true"
                  v-model="instDate[item.key]"
                  :placeholder="`请输入${item.name}`"
                ></el-input>
                <span v-else>/</span>
              </div>
            </el-descriptions-item>
          </el-descriptions> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    instruction: {
      type: Array,
      default: () => [],
    }, //商品说明书
    instDate: {
      type: Object,
      default: () => {},
    }, //说明书数据
    pattern:{

    },
    richText:{

    }
  },
  methods:{
    changePattern(val){
      this.$emit('changePattern',val)
    }
  }
};
</script>
<style lang='scss' scoped>
.el-descriptions-item__label {
  margin-right: 10px;
  width: 200px !important;
  display: flex;
  align-items: center;
  justify-content: right;
}
</style>
<style lang="scss" scoped>
.message-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .title {
    margin-top: 10px;
    width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .message-box-box {
    // margin-top: 10px;
    // display: flex;
    // justify-content: space-between;
    width: 100%;
  }
  .message-box-left {
    margin-right: 10px;
    padding-left: 20px;
    padding-top: 10px;
    width: 100%;
    // border: 1px solid #797979;
    border-radius: 4px;
  }
  .message-box-right {
    width: 48%;
    .goods-img-box {
      width: 100%;
      .el-image {
        margin-right: 5px;
      }
      .goods-img {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        // border: 1px solid #797979;
        padding-left: 10px;
        margin-top: 10px;
      }
    }
    .vender-message-box {
      width: 100%;
      .vender-message {
        padding-top: 10px;
        // border: 1px solid #797979;
        padding-left: 10px;
        border-radius: 4px;
        margin-top: 10px;
        width: 100%;
      }
    }
    .label-message {
      padding: 10px;
      width: 100%;
      // border: 1px solid #797979;
      padding-left: 10px;
      margin-top: 10px;
      border-radius: 4px;
    }
  }
}
.input-box {
  // width: 75vw;
}
.box {
  margin-top: 20px;
  .lable {
    margin-bottom: 10px;
    font-size: 14px;
  }
}
.pattern{
  margin-top: 20px;
  display: flex;
  >div{
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #333;
    font-size: 14px;
    margin-right: 10px;
  }
  .chose{
  background-color: #06b7ae;
  color: #fff;
  border-radius: 4px;
}
}

</style>
