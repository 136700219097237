<template>
  <div class="MERCH goodMessage">
    <div class="merch-card">
      <div class="message-box scorr-roll">
        <!-- 基本信息 -->
        <div>
          <BasicMessageGood
            v-if="AuditbusinessData.pictureUrlList"
            :basicInfo2="goodsMessage.basicInfo2"
            :basicInfo3="goodsMessage.basicInfo3"
            :picture="goodsMessage.picture"
            :manufacturerInfo="goodsMessage.manufacturerInfo"
            :label="goodsMessage.label"
            :basicinfoData="AuditbusinessData.basicinfo"
            :imgDate="AuditbusinessData.pictureUrlList"
            :manuData="AuditbusinessData"
            :labData="AuditbusinessData"
            @removeImg="removeImg"
            @updateImg="updateImg"
            :showUpdateImg="$route.query.type ? false : true"
          />
        </div>
        <!-- 商品说明书 -->
        <div v-show="AuditbusinessData.contentType == 1">
          <ExplainGood
            v-if="$route.query.type"
            :instruction="goodsMessage.instruction"
            :instDate="AuditbusinessData.contentObj"
            @changePattern="changePattern"
          />
          <ExplainGood1
            v-else
            :instruction="goodsMessage.instruction"
            :instDate="AuditbusinessData.contentObj"
            @updateExplain="updateExplain"
            :showUpdateExplain="$route.query.type ? false : true"
          />
        </div>
        <div
          v-show="AuditbusinessData.contentType == 2"
          class="message-box-left"
        >
          <div class="title">说明书</div>
          <div class="pattern" v-if="$route.query.type">
            <div @click="changePattern(1)">表格模式</div>
            <div class="chose" @click="changePattern(2)">富文本模式</div>
          </div>
          <pre><Editor v-model="AuditbusinessData.content" class="Editor" /></pre>
        </div>
      </div>
      <div class="btn-box">
        <div class="refuse" @click="toBack">返回</div>
        <div class="pass" @click="toSave" v-if="$route.query.type == 3">
          保存
        </div>
      </div>
      <!-- <div class="btn-box" v-else>
        <div class="refuse" @click="toBack">返回</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import BasicMessageGood from "./comp/basMessage.vue"; //基本信息
import BasicMessageGood1 from "../goodCloud/goodCloudBasic.vue"; //基本信息
import ExplainGood1 from "@/components/ordinaryGoods/explain.vue"; //商品说明书
import ExplainGood from "./comp/splGoods.vue"; //商品说明书
import Editor from "@/components/Editor/index.vue"; //商品说明书
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {
      activeName: 0,
      goodsMessage: require("../../../assets/json/goodMessage.json"), // 引入详情json
      AuditMessage: {
        id: this.$route.query.id,
        remark: "",
        status: 2,
      },
      isRefuse: false, //孔氏拒绝弹框
      ispass: false, //通过拒绝类型
      AuditbusinessData: {},
      form: "",
      pattern: 1,
    };
  },
  components: {
    BasicMessageGood, //基本信息
    BasicMessageGood1,
    ExplainGood, //商品说明书
    ExplainGood1, //商品说明书
    Editor,
  },
  created() {
    if (this.$route.query.id) {
      this.getMessage();
    }
  },
  methods: {
    ...mapActions([
      "postgoodsAuditaudit",
      // "postplatformGoodsapplyEdit",
      "postplatformGoodsEdit",
      "postsyncBigDataSyncPicture",
      "postsyncBigDataSyncInstruction",
      "getgoodsDetail",
      "postgoodsEdit",
    ]),
    ...commonIndex.mapMutations(["ChangegoodsDialogVisible"]),
    changePattern(val) {
      this.AuditbusinessData.contentType = val;
      let message = "";
      if (val == 1) {
        message = "说明书切换至表格模式";
      } else if (val == 2) {
        message = "说明书切换至富文本模式";
      }
      this.$message({
        message: message,
        type: "success",
      });
    },
    async updateImg() {
      console.log(this.AuditbusinessData,'this.AuditbusinessDatathis.AuditbusinessData');
      if (this.AuditbusinessData.pictureUrlList.length == 6) {
        this.$message({
          message: "当前商品图片数量已达上限，无法从标准资料库中获取图片信息",
          type: "warning",
        });
        return;
      }
      let res = await this.postsyncBigDataSyncPicture(this.$route.query.id);
      if (res.code == "000000") {
        this.$message({
          message: "商品图片更新成功，请查看并核对是否与商品实物一致。",
          type: "success",
        });
        this.getMessage();
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
      }
    },
    async updateExplain() {
      // this.ChangegoodsDialogVisible(false)
      let res = await this.postsyncBigDataSyncInstruction(this.$route.query.id);
      if (res.code == "000000") {
        this.$message({
          message: "商品说明书更新成功，请查看并核对是否与商品实物一致。",
          type: "success",
        });
        this.getMessage();
        this.ChangegoodsDialogVisible(false);
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
      }
    },
    // 获取详情
    async getMessage() {
      let data = await this.getgoodsDetail({
        id: this.$route.query.id,
      });

      // data.content.businessScopeId = [];

      // if (data.content.businessScopeId.length > 0) {
      //   data.content.businessScopeId.forEach((item) => {
      //     data.content.businessScopeId.push({
      //       id: item,
      //     });
      //   });
      // } else {
      //   data.content.businessScopeId.push({
      //     id: "",
      //   });
      // }
      // 处理分类数据
      // data.content.goodsCategoryId = [];
      // if (data.content.goodsCategoryId.length > 0) {
      //   // data.data.goods_category_id.forEach((item) => {
      //   //   data.data.Newgoods_category_id.push({
      //   //     id: item,
      //   //   });
      //   // });
      //   // let newData = [[7, 67, 193]];

      //   // data.content.goodsCategoryId.forEach((item) => {
      //   //   item = item * 1;
      //   //   newData.push(item);
      //   // });

      //   data.content.goodsCategoryId =  data.content.goodsCategoryIdList;
      //   // data.data.Newgoods_category_id = data.data.goods_category_id;
      //   // data.data.Newgoods_category_id.forEach((item) => {
      //   //   //console.log(item);
      //   //   Number(item);
      //   // });
      // } else {
      //   // data.data.Newgoods_category_id.push({
      //   //   id: "",
      //   // });
      // }
      // data.content.businessScopeId='4'
      if (data.content.goodsCategoryIdList.length == 0) {
        data.content.goodsCategoryIdList = [[]];
      }
      data.content.goodsCategoryId = data.content.goodsCategoryIdList;
      // data.content.content=data.content.contentObj
      this.AuditbusinessData = data.content;
      if (this.AuditbusinessData.businessScopeId == 0) {
        this.AuditbusinessData.businessScopeId = "";
      }
      console.log(this.AuditbusinessData);

      // return
      // if (this.AuditbusinessData.content.length == 0) {
      //   this.AuditbusinessData.content = {
      //     ingredients: "",
      //     character: "",
      //     indications: "",
      //     usage: "",
      //     adverseReactions: "",
      //     taboo: "",
      //     attentions: "",
      //     isForPregnantWoman: "",
      //     isForKid: "",
      //     isForOld: "",
      //     drugInteraction: "",
      //     drugOverdose: "",
      //     pharmacologicAction: "",
      //     drugToxicology: "",
      //     pharmacokinetics: "",
      //   };
      // }
    },
    // 保存
    async toSave() {
      // this.AuditbusinessData.business_scope_id = [];
      // this.AuditbusinessData.goods_category_id = [];
      // this.AuditbusinessData.Newbusiness_scope_id.forEach((item) => {
      //   this.AuditbusinessData.business_scope_id.push(item.id);
      // });
      // this.AuditbusinessData.Newgoods_category_id.forEach((item) => {
      //   this.AuditbusinessData.goods_category_id.push(item.id);
      // });
      // 储存分类
      if (this.AuditbusinessData.goodsCategoryId.length == 0) {
        this.$message({
          message: "商品分类未选择",
          type: "warning",
        });
        return;
      }
      if (this.AuditbusinessData.businessScopeId == "") {
        this.AuditbusinessData.businessScopeId = 0;
      }
      // this.AuditbusinessData.goods_category_id=this.AuditbusinessData.all_goods_category

      let params = {
        // platformGoodsInfoId: this.AuditbusinessData.id,
        // goodsName: this.AuditbusinessData.name,
        // approvalNumber: this.AuditbusinessData.approval_number,
        // businessScopeId: this.AuditbusinessData.businessScopeId,
        // content: this.AuditbusinessData,
        ...this.AuditbusinessData,
      };
      // params.contentType=this.
      console.log(params);
      params.contents = params.content;
      params.content = "";
      // return
      let data = await this.postgoodsEdit(params);
      if (data.code == "000000") {
        this.$router.push({
          path: "/goodsLibrary",
        });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    changePattern(val){
     this.AuditbusinessData.contentType=val
      // this.$emit('changePattern',val)
    },
    // 删除图片
    removeImg(url) {
      this.AuditbusinessData.picture_url =
        this.AuditbusinessData.picture_url.filter((item) => {
          return item != url;
        });
    },
    //  返回
    toBack() {
      // this.$router.push({ path: "/goodsLibrary" });
      window.history.go(-1);
    },
    // 拒绝弹框
    refuseMessage() {
      this.isRefuse = true;
    },
    // 通过弹框
    passMessage() {
      this.ispass = true;
    },
    // 通过
    async nextPass() {
      this.AuditMessage = {
        id: this.$route.query.id,
        status: 2,
      };
      let data = await this.postgoodsAuditaudit(this.AuditMessage);
      if (data.code == 200) {
        this.ispass = false;
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 确认拒绝
    async notarizeRefuse() {
      this.AuditMessage.status = 3;
      let data = await this.postgoodsAuditaudit(this.AuditMessage);
      if (data.code == 200) {
        this.isRefuse = false;
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 供应商审核
    goodAudit() {
      this.AuditMessage = {
        id: this.$route.query.id,
      };
    },
    handleCurrentChange() {},
    toSearch() {},
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    //
    toMessage() {
      this.$router.push({ path: "/cloudGoodMessage" });
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .message-box {
      width: 100%;
      max-height: 75vh;
      padding-top: 10px;
      .time-wire {
        width: 100%;
        height: 100px;
        background: rebeccapurple;
      }
    }
  }
  .btn-box {
    cursor: pointer;
    margin-top: 1%;
    width: 100%;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    div {
      width: 90px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      text-align: center;
      border-radius: 4px;
      font-weight: normal;
    }
    .pass {
      margin-left: 15px;
      background: #06b7ae;
      color: #ffffff;
    }
    .refuse {
      background: #f2f2f2;
      color: #999999;
    }
  }
}
.title {
  margin-top: 10px;
  width: 100%;
  font-weight: bold;
  padding-bottom: 16px;
  border-bottom: dashed 1px #ddd;
  display: flex;
  align-items: center;
}
.title::before {
  width: 4px;
  height: 16px;
  background-color: #06b7ae;
  border-radius: 10px;
  display: inline-block;
  content: "";
  margin-right: 10px;
  margin-bottom: -2px;
}
.pattern {
  margin-top: 20px;
  display: flex;
  > div {
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #333;
    font-size: 14px;
    margin-right: 10px;
  }
  .chose {
    background-color: #06b7ae;
    color: #fff;
    border-radius: 4px;
  }
}
.message-box-left {
  margin-right: 10px;
  padding-left: 20px;
  padding-top: 10px;
  width: 100%;
  // border: 1px solid #797979;
  border-radius: 4px;
}
</style>
