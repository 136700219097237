<template>
  <div>
    <div class="message-box">
      <!-- <div class="title">商品基本信息</div> -->
      <div class="message-box-box">
        <div class="message-box-left">
          <div class="title">商品基本信息</div>
          <!-- <el-descriptions :column="2"> -->
          <div
            :label="item.name"
            v-for="(item, index) in basicInfo2"
            :key="index + ''"
            class="basicInfo2-style"
          >
            <div class="lable">{{ item.name }}:</div>

            <template v-if="item.select">
              <!-- {{ item.key }}--{{item.selKey}}--{{manuData[item.selKey]}} -->
              <div
                class="select-span"

              >
                <!-- <span>{{val}}</span> -->
                <!-- {{ val }} -->
                <el-select
                clearable
                  v-if="$route.query.type"
                  :disabled="$route.query.type == 3 ? false : true"
                  v-model="manuData.businessScopeId"
                  :placeholder="`请输入${item.name}`"
                >
                  <el-option
                    v-for="elem in BasissCopeData[item.selKey]"
                    :key="elem.id"
                    :label="elem[item.value]"
                    :value="elem.id + ''"
                  >
                  </el-option>
                  <!-- {{ val.id }} -->
                </el-select>
                <div v-if="!$route.query.type">
                  {{ manuData.businessscopestring}}
                </div>

                <!-- <template v-if="$route.query.type == 3">
                      <span
                        @click="removeType(item.selKey, i)"
                        class="exceed_color font-size-12"
                        v-if="i != 0"
                        >删除</span
                      >
                      <span
                        class="exceed_color font-size-12"
                        v-if="i == manuData[item.selKey].length - 1"
                        @click="AddType(item.selKey)"
                        >添加11</span
                      >
                    </template> -->
              </div>
            </template>
            <div>
              <template v-if="item.Cascader">
                <div v-for="(val, i) in manuData.goodsCategoryId" :key="i">
                  <div v-if="$route.query.type">
                    <el-cascader
                      class="category"
                      :disabled="$route.query.type == 3 ? false : true"
                      v-model="manuData.goodsCategoryId[i]"
                      :options="BasissCopeData.Newgoods_categoryList"
                      :props="propsData"
                      :placeholder="`请输入${item.name}`"
                      clearable
                    ></el-cascader>
                    <span
                      class="exceed_color font-size-12"
                      @click="AddClassify(item.selKey)"
                      v-if="
                        i == manuData.goodsCategoryId.length - 1 &&
                        $route.query.type == 3
                      "
                      >添加</span
                    >
                    <span
                      @click="removeClassify(item.selKey, i)"
                      v-if="i != 0 && $route.query.type == 3"
                      class="exceed_color font-size-12"
                      >删除</span
                    >
                  </div>
                  <div v-else>
                    <!-- {{CategoryByIdAll[i]}} -->
                  </div>
                </div>
                <div v-if="!$route.query.type">
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      {{ CategoryByIdAll[0]
                      }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(val, i) in manuData.goodsCategoryIdList"
                        :key="i"
                        >{{ CategoryByIdAll[i] }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
              <template v-if="item.key == 'category'">
                <!-- <el-input
                  v-if="$route.query.type || manuData[item.key] == 0"
                  :disabled="$route.query.type == 3 ? false : true"
                  v-model="manuData[item.key]"
                  :placeholder="`请输入${item.name}`"
                ></el-input> -->
                <div v-if="$route.query.type">
                  <el-radio-group v-model="manuData[item.key]">
                    <el-radio :label="'0'">普药</el-radio>
                    <el-radio :label="'1'">中药</el-radio>
                    <el-radio :label="'2'">非药品</el-radio>
                  </el-radio-group>
                  <!-- <el-select
                  v-model="manuData[item.key]"
                  :disabled="$route.query.type == 3 ? false : true"
                >
                  <el-option label="普药" :value="0"> </el-option>
                  <el-option label="中药" :value="1"> </el-option>
                  <el-option label="非药品" :value="2"> </el-option>
                </el-select> -->
                </div>
                <div v-else>
                  {{
                    manuData[item.key] == 0
                      ? "普药"
                      : manuData[item.key] == 1
                      ? "中药"
                      : manuData[item.key] == 2
                      ? "非药品"
                      : "/"
                  }}
                </div>
              </template>
            </div>
          </div>
          <div class="basicInfo3-box">
            <div
              :label="item.name"
              v-for="(item, index) in basicInfo3"
              :key="index + ''"
              class="basicInfo3-style"
            >
              <div class="lable">{{ item.name }}:</div>
              <template v-if="item.select || item.radio">
                <!--  -->
                <template v-if="item.radio">
                  <el-radio
                    :disabled="$route.query.type == 3 ? false : true"
                    v-for="v in item.padioCheck"
                    :key="v.val"
                    v-model="manuData[item.key]"
                    :label="v.val"
                  ></el-radio>
                </template>
              </template>

              <!--  -->

              <template
                v-else-if="
                  !item.select ||
                  (!item.radio && !item.Cascader && item.key != 'category')
                "
              >
                <div v-if="$route.query.type">
                  <el-input
                    v-if="$route.query.type || manuData[item.key]"
                    :disabled="$route.query.type == 3 ? false : true"
                    v-model="manuData[item.key]"
                    :placeholder="`请输入${item.name}`"
                  ></el-input>
                  <span v-else>/</span>
                </div>
                <div v-else>
                  {{ manuData[item.key] ? manuData[item.key] : "/" }}
                </div>
              </template>
            </div>
          </div>
          <!-- </el-descriptions> -->
        </div>
        <div class="message-box-right">
          <!-- 商品图片 -->
          <div
            class="goods-img-box"
            v-for="(item, index) in picture"
            :key="index + ''"
          >
            <div class="updateImg">
              <div class="title">{{ item.name }}</div>
              <div v-if="showUpdateImg" @click="updateImg"><span>更新商品图片</span></div>
            </div>

            <div class="goods-img">
              <template v-if="$route.query.type != 3">
                <el-image
                  v-for="(item, index) in imgDate"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item"
                  :preview-src-list="[item]"
                ></el-image>
              </template>
              <template v-else>
                <div class="up-box-bas">
                  <el-upload
                    :action="Actions"
                    list-type="picture-card"
                    :limit="6"
                    :headers="token"
                    :file-list="imageList"
                    :before-upload="beforeAvatarUpload"
                    :data="{ type: '1' }"
                    :on-success="successUpImg"
                    :on-exceed="onExceed"
                  >
                    <i class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{ file }">
                      <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url"
                        alt=""
                      />
                      <span class="el-upload-list__item-actions">
                        <span
                          class="el-upload-list__item-delete"
                          @click="handleRemove(file)"
                        >
                          <i class="el-icon-delete" title="删除" />
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </div>
              </template>
            </div>
          </div>

          <!-- 厂家信息 -->
          <div class="vender-message-box">
            <div class="title">厂家信息</div>
            <div class="vender-message manufacturers">
              <!-- <el-descriptions :column="2" class="manufacturers"> -->
              <div v-for="(item, index) in manufacturerInfo" :key="index">
                <!-- <el-descriptions-item
                  v-for="(item, index) in manufacturerInfo"
                  :key="index"
                > -->
                <div>{{ item.name }}</div>
                <el-input
                  v-if="$route.query.type"
                  :disabled="$route.query.type == 3 ? false : true"
                  v-model="manuData[item.key]"
                  :placeholder="`请输入${item.name}`"
                ></el-input>
                <el-tooltip
                  :content="manuData[item.key]"
                  placement="top"
                  v-else
                >
                  <span class="tooltip">{{ manuData[item.key] || "/" }}</span>
                </el-tooltip>
              </div>

              <!-- <el-input
                    v-if="$route.query.type || manuData[item.key]"
                    :disabled="$route.query.type == 3 ? false : true"
                    v-model="manuData[item.key]"
                    :placeholder="`请输入${item.name}`"
                  ></el-input>
                  <span v-else>/</span> -->
              <!-- </el-descriptions-item> -->
              <!-- </el-descriptions> -->
            </div>
          </div>
          <!-- 标签信息 -->
          <div class="label-message-box">
            <div class="title">标签信息</div>
            <div class="label-message">
              <div v-if="$route.query.type == 3">
                <el-descriptions :column="2">
                  <el-descriptions-item
                    :label="item.name"
                    v-for="(item, index) in label"
                    :key="index"
                  >
                    <template v-if="item.radio">
                      <el-radio
                        :disabled="$route.query.type == 3 ? false : true"
                        v-for="v in item.padioCheck"
                        :key="v.val"
                        v-model="labData[item.key]"
                        :label="v.val"
                        >{{ v.typeName }}</el-radio
                      >
                    </template>
                  </el-descriptions-item>
                </el-descriptions>
              </div>
              <div v-if="$route.query.type != 3">
                <el-descriptions :column="2">
                  <el-descriptions-item
                    :label="item.name"
                    v-for="(item, index) in label"
                    :key="index"
                  >
                    <template v-if="item.radio">
                      {{ labData[item.key] == 1 ? "是" : "否" }}
                    </template>
                    <!-- {{item.name}}: -->
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { wholeUrl } from "@/api/phpUrl"; //引入接口
import compressImage from "@/utils/compressImage.js";
import { boolean } from "babel-standalone";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
import { getToken } from "@/utils/auth";

export default {
  props: {
    basicInfo2: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品基本信息
    basicInfo3: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品基本信息
    picture: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品图片
    manufacturerInfo: {
      type: Array,
      default: () => {
        return [];
      },
    }, //厂家信息
    label: {
      type: Array,
      default: () => {
        return [];
      },
    }, //标签信息,
    basicinfoData: {
      type: Object,
      default: () => {
        return {};
      },
    }, //数据商品基本
    imgDate: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品图片
    manuData: {
      type: Object,
      default: () => {
        return {};
      },
    }, //厂家信息
    labData: {
      type: Object,
      default: () => {
        return {};
      },
    }, //标签信息
    showUpdateImg:{
      type:Boolean
    },
     isUpdate: {
      type: Boolean,
    },
  },
  data() {
    return {
      options: [],
      Actions: wholeUrl + "/basis/upload",
      token: { token: getToken()},
      imageList: [],
      propsData: {
        value: "id",
        label: "category_name",
        children: "son",
        //级联选择器配置项
      },
      CategoryByIdAll: [],
    };
  },
  created() {
    // let categoryList=this.manuData.all_goods_category
    // this.manuData.Newgoods_categoryList=[]
    // this.manuData.Newgoods_categoryList.push(categoryList)
    // console.log(this.manuData.Newgoods_categoryList)
  },
  computed: {
    ...commonIndex.mapState(["BasissCopeData", "goodSIFY"]),
  },
  mounted() {
    this.CategoryById();
    this.$nextTick(() => {
      this.readImg();
    });
  },
  methods: {
    ...commonIndex.mapActions(["categorycategory"]),
    ...mapActions(["postgoodsGetCategoryById"]),
    onExceed(files, fileList){
      this.$message.error("超出图片数量上限");
    },
    updateImg(){
      this.$emit("updateImg");
    },
    async beforeAvatarUpload(file) {
      if (file.size / 1024 / 1024 > 50)
        return this.$message.error("上传头像图片大小不能超过 50MB!");
      return await compressImage([file]);
    },

    readImg() {
      this.imgDate.forEach((item) => {
        this.imageList.push({ url: item });
      });
    },
    async CategoryById() {
      let res = await this.postgoodsGetCategoryById( this.manuData.goodsCategoryIdList);

      //  处理
      res.content.map((v, index) => {
        let strArr = [];
        //  console.log("vvv",v)
        v.forEach((v1) => {
          if (v1) strArr.push(v1);
        });
        let aa = strArr.join("/");
        this.CategoryByIdAll.push(aa);
      });
    },
    // 成功数据
    successUpImg(data) {
      if (data.code == 200) {
        this.imageList.push({
          url: data.data.full_url,
        });
        this.imgDate.push(data.data.full_url);
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
      //
    },
    // 删除上传的图片
    handleRemove(data) {
      //console.log(data);
      this.imageList = this.imageList.filter((item) => {
        return item.url != data.url;
      });
      this.$emit("removeImg", data.url);
    },
   
    //删除
    removeType(key, idnex) {
      this.manuData[key].splice(idnex, 1);
    },
    // 添加
    AddType(key) {
      this.manuData[key].push({ id: "" });
    },
    //删除分类
    removeClassify(key, index) {
      // this.manuData[key].splice(idnex, 1);
      this.manuData.goodsCategoryId.splice(index, 1);
    },
    // 添加分类
    AddClassify(key) {
      if (this.manuData.goodsCategoryId.length < 10) {
        this.manuData.goodsCategoryId.push([]);
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.up-box-bas .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.up-box-bas .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
</style>
<style lang="scss" scoped>
.message-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // .title {
  //   width: 120px;
  //   margin-top: 10px;
  //   height: 30px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   background: #00bfbf;
  //   color: #fff;
  //   border-radius: 4px;
  // }
  .category {
    margin-bottom: 2px;
  }
  .message-box-box {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .message-box-left {
    margin-right: 10px;
    padding-left: 20px;
    padding-top: 10px;
    width: 48%;
    // border: 1px solid #797979;
    border-radius: 4px;
    ::v-deep .el-descriptions-item__label {
      margin-right: 10px;
      width: 80px !important;
      display: flex;
      align-items: center;
      justify-content: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
  }
  .select-span {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
  }
  .message-box-right {
    width: 48%;
    .goods-img-box {
      width: 100%;
      .el-image {
        margin-right: 5px;
      }
      .goods-img {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        // border: 1px solid #797979;
        padding-left: 10px;
        margin-top: 10px;
      }
    }
    .vender-message-box {
      width: 100%;
      .vender-message {
        padding-top: 10px;
        // border: 1px solid #797979;
        padding-left: 10px;
        border-radius: 4px;
        margin-top: 10px;
        width: 100%;
      }
      ::v-deep .el-input {
        // width: 125%;
        flex: 1;
      }
    }
    .label-message {
      padding: 10px;
      width: 100%;
      // border: 1px solid #797979;
      padding-left: 10px;
      margin-top: 10px;
      border-radius: 4px;
    }
  }
}
.title {
  margin-top: 10px;
  width: 100%;
  font-weight: bold;
  padding-bottom: 16px;
  border-bottom: dashed 1px #ddd;
  display: flex;
  align-items: center;
}
.title::before {
  width: 4px;
  height: 16px;
  background-color: #06b7ae;
  border-radius: 10px;
  display: inline-block;
  content: "";
  margin-right: 10px;
  margin-bottom: -2px;
}
::v-deep .el-descriptions-item__label {
  margin-right: 10px;
  width: 30% !important;
  display: flex;
  align-items: center;
  justify-content: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.label-message-box {
  ::v-deep .el-descriptions-item__label {
    margin-right: 10px;
    width: 80px !important;
    display: flex;
    align-items: center;
    justify-content: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
}
::v-deep .el-descriptions-item__cell {
  padding-top: 10px !important;
}

// ::v-deep.el-input.is-disabled{
//   background-color:white !important;
//   border-color:white !important;
//   color:black !important
// }
// ::v-deep.el-input__icon:after{
//   display: none !important;
// }
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: white;
  border-color: white;
  color: #606266;
}
::v-deep .el-icon-arrow-up:before {
  display: none;
}
.el-dropdown-link {
  color: #06b7ae;
}

::v-deep .el-descriptions-item__label.has-colon::after {
  content: "";
}
.manufacturers {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);

  font-size: 14px;
  color: #666;
  > div {
    display: flex;
    height: 40px;
    margin-right: 10px;
    line-height: 40px;
    > div {
      width: 120px;
      white-space: nowrap;
    }
    .tooltip {
      margin-left: 10px;
      color: #333;
      white-space: nowrap;
      flex: 1;
    }
  }
}
.basicInfo2-style {
  display: flex;
  margin-top: 10px;
  line-height: 32px !important;
  font-size: 14px;
  color: #333;
  .lable {
    line-height: 32px;
    height: 32px;
    margin-right: 5px;
    width: 90px;
    color: #666;
  }
}
.basicInfo3-box {
  display: flex;
  flex-wrap: wrap;
}
.basicInfo3-style {
  display: flex;
  width: 50%;
  margin-top: 10px;
  line-height: 32px !important;
  font-size: 14px;
  color: #333;
  .lable {
    line-height: 32px;
    height: 32px;
    margin-right: 5px;
    width: 90px;
    color: #666;
  }
}
::v-deep .el-input__inner {
  height: 32px;
}
.updateImg {
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  div{
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    span{
      width: 100px;
      display: inline-block;
      font-size: 14px;
      background-color: #06b7ae;
      color: #fff;
      text-align: center;
      height: 24px;
      line-height: 24px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
::v-deep .el-input__icon{
  line-height: 32px;
}
</style>
