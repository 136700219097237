<template>
  <div>
    <div class="message-box">
      <!-- <div class="title">商品基本信息</div> -->
      <div class="message-box-box">
        <!-- <div>商品基本信息</div> -->
        <div class="message-box-left">
            <div class="title">商品基本信息</div>

          <el-descriptions class="message-l" :column="2">
            <el-descriptions-item
              :label="item.name"
              v-for="(item, index) in basicInfo"
              :key="index + ''"
            >
            <!-- / -->
              <span v-if="item.radio">
                <span v-for="(v, i) in item.padioCheck" :key="i">
                  <span v-if="basicinfoData[item.key] == v.val">{{
                    v.typeName
                  }}</span>
                </span></span
              >
              <template v-else-if="item.Cascader" >
                <div class="Cascader-box">
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      {{ basicinfoData.classification_goods[0]
                      }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(val, i) in basicinfoData.classification_goods"
                        :key="i"
                        >{{ basicinfoData.classification_goods[i] }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                  <!-- <div
                  v-for="(value, i) in basicinfoData.classification_goods"
                  :key="i"
                  class="Cascader-abs"
                >
                
                                  {{CategoryByIdAll[i]}} -->
                    
                 <!-- 注释的选择器 -->
                  <!-- <el-cascader
                    disabled
                    v-model="manuData.classification_goods[i]"
                    :options="options"
                    v-if="i == 0"
                  ></el-cascader
                  ><span
                    v-if="i == 0 && manuData.classification_goods.length > 1&&!more"
                    @click="more=true"
                    >更多</span
                  >
                  <span
                    v-if="i == 0 && manuData.classification_goods.length > 1&&more"
                    @click="more=false"
                    >收起</span
                  >
                  <el-cascader
                    disabled
                    v-model="manuData.classification_goods[i]"
                    :options="options"
                    v-if="i != 0&&more"
                  ></el-cascader> -->


                  <!-- 下面为之前就注释了的 -->
                  <!-- <el-cascader
                    class="category"
                    v-model="manuData.classification_goods[i]"
                    :options="basicInfo.Newgoods_categoryList"
                    :props="propsData"
                    :placeholder="`请输入${item.name}`"
                    clearable
                  ></el-cascader> -->
                <!-- </div> -->
                
                </div>
                
              </template>
              <span v-else>{{ basicinfoData[item.key] || "/" }}</span>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="message-box-right">
          <!-- 商品图片 -->
          <div
            class="goods-img-box"
            v-for="(item, index) in picture"
            :key="index + ''"
          >
            <div class="title">{{ item.name }}</div>
            
            <div class="goods-img">
              <el-image
                v-for="(item, index) in imgDate"
                :key="index"
                style="width: 100px; height: 100px"
                :src="item"
                :preview-src-list="[item]"
              >
              </el-image>
            </div>
          </div>
          <!-- 厂家信息 -->
          <div class="vender-message-box">
            <div class="title">生产厂家信息</div>
            
            <div class="vender-message">
              <el-descriptions :column="2">
                <el-descriptions-item
                  :label="item.name"
                  v-for="(item, index) in manufacturerInfo"
                  :key="index"
                >
                  {{ manuData[item.key] || "/" }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
          <!-- 标签信息 -->
          <div class="label-message-box">
            <div class="title">标签信息</div>
            <div class="label-message">
              <el-descriptions :column="2">
                <el-descriptions-item
                  :label="item.name"
                  v-for="(item, index) in label"
                  :key="index"
                >
                  {{ labData[item.key] == 1 ? "是" : "否" }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
export default {
  props: {
    basicInfo: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品基本信息
    picture: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品图片
    manufacturerInfo: {
      type: Array,
      default: () => {
        return [];
      },
    }, //厂家信息
    label: {
      type: Array,
      default: () => {
        return [];
      },
    }, //标签信息,
    basicinfoData: {
      type: Object,
      default: () => {
        return {};
      },
    }, //数据商品基本
    imgDate: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品图片
    manuData: {
      type: Object,
      default: () => {
        return {};
      },
    }, //厂家信息
    labData: {
      type: Object,
      default: () => {
        return {};
      },
    }, //标签信息
  },
  data() {
    return {
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      propsData: {
        value: "id",
        label: "category_name",
        children: "son",
        //级联选择器配置项
      },
      options: [],
      more:false,
            CategoryByIdAll:[]

    };
  },
  created() {
    this.getlist();
        // this.CategoryById()

  },
  mounted() {
    
    this.$nextTick(() => {
          // this.CategoryById()

    });
  },
  methods: {
        //  ...commonIndex.mapActions(["categorycategory"]),

    ...mapActions(["getcategorylist", "postGetCategoryById"]),
    async getlist() {
      let data = await this.getcategorylist();
      let res = [];
      data.data.forEach((v) => {
        let obj = {
          value: v.id,
          label: v.category_name,
        };
        if (v.son) {
          obj.children = [];
          v.son.forEach((v1) => {
            let obj1 = {
              value: v1.id,
              label: v1.category_name,
            };
            if (v1.son) {
              obj1.children = [];
              v1.son.forEach((v2) => {
                let obj2 = {
                  value: v2.id,
                  label: v2.category_name,
                };
                obj1.children.push(obj2);
              });
            }
            obj.children.push(obj1);
          });
        }
        res.push(obj);
      });
      this.options = res;
    },
      async CategoryById(){
        setTimeout(async() => {
           let res=await this.postGetCategoryById({category:this.manuData.classification_goods})
        res.data.map((v,index) => {
           let strArr=[]
          v.forEach(v1 => {
          
           if(v1)strArr.push(v1)
          });
          let aa =strArr.join('>')
           this.CategoryByIdAll.push(aa)
        });
        }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.message-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .title {
     width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .message-box-box {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .message-box-left {
    margin-right: 10px;
    padding-left: 20px;
    padding-top: 10px;
    width: 40%;
    // border-radius: 4px;
  }
  .message-box-right {
    width: 48%;
    padding-top: 0.65%;
    .goods-img-box {
      width: 100%;
      .el-image {
        margin-right: 5px;
      }
      .goods-img {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        padding-left: 10px;
        margin-top: 10px;
      }
    }
    .vender-message-box {
      margin-top: 30px;
      width: 100%;
      .vender-message {
        padding-left: 10px;
        // border-radius: 4px;
        margin-top: 10px;
        width: 100%;
      }
    }
    .label-message {
      width: 100%;
      padding-left: 10px;
      margin-top: 10px;
      // border-radius: 4px;
    }
  }
}
::v-deep .el-descriptions-item__content{
  position: relative;
}

.Cascader-box{
  position: absolute;
  width: 300px;
  background-color: #fff;
  z-index: 9;
  span{
    color: #00bfbf;
    font-size: 12px;
    cursor: pointer;
  }
}
::v-deep .el-descriptions-item__cell{
  padding-top: 10px;
  height: 40px;
  box-sizing: border-box;
  padding-bottom: 10px;
}
::v-deep .el-descriptions-item__label {
  margin-right: 10px;
  width: 120px !important;
  display: flex;
  align-items: center;
  justify-content: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.message-l{
::v-deep .el-descriptions-item__label {
  margin-right: 10px;
  width: 80px !important;
  display: flex;
  align-items: center;
  justify-content: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
}
.label-message-box{
  margin-top: 30px;
}
.Cascader-abs{
  line-height: 1;
  
}
.Cascader-box{
  width: 200px;
}
</style>
